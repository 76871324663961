import { applyMiddleware, createStore, combineReducers } from 'redux';
import middleware from './middleware';

const stories = (state = [], action) => {
	switch (action.type) {
		case 'UPDATE_STORIES': return action.stories;
		case 'EMPTY_STORIES': return [];
		default: return state;
	}
};

const loadStories = (state = false, action) => {
	switch (action.type) {
		case 'LOAD_STORIES': return action.loadStories;
		default: return state;
	}
};

const currentStory = (state = null, action) => {
	switch (action.type) {
		case 'UPDATE_CURRENT_STORY': return action.currentStory;
		default: return state;
	}
};

const copy = (state = false, action) => {
	switch (action.type) {
		case 'COPY_STORIES': return action.copy;
		default: return state;
	}
};

const save = (state = false, action) => {
	switch (action.type) {
		case 'SAVE_STORIES': return action.save;
		default: return state;
	}
};

const saved = (state = false, action) => {
	switch (action.type) {
		case 'SAVED_STORIES': return action.saved;
		default: return state;
	}
};

const reset = (state = false, action) => {
	switch (action.type) {
		case 'RESET_STORIES': return action.reset;
		default: return state;
	}
};

const rootReducer = combineReducers({
	stories,
	loadStories,
	currentStory,
	copy,
	save,
	saved,
	reset
});

export default rootReducer;

// Create the store here, to make getting test instances easier
export const createAppStore = () => createStore(rootReducer, applyMiddleware(...middleware));
