import React from 'react';
import { v4 as uuid } from 'uuid';   
import createKey from '../../../../utils';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const StoryPreview = ({props}) => {
  const {
    stories,
    showStoryInput,
    currentStory,
    preview,
  } = props;

  const getStoryName = () => {
    if(currentStory === undefined) return;
    return stories[currentStory].name;
  }

  const getStoryNameWithStoryPoints = () => {
    if(currentStory === undefined) return;
    console.log(stories[currentStory || 0].storyPoints);
    const renderStoryPoints = stories[currentStory || 0].storyPoint
      ? `(${stories[currentStory || 0].storyPoints} story points)`
      : '';
    return <span>
        {stories[currentStory].name}
        {' '}
        <span style={{
          fontSize: 17,
          fontWeight: 'normal',
          color: '#8a8989'
        }}>
          {renderStoryPoints}
        </span>
      </span>;
  }

  const removeLastFullStop = (taskName) => {
    const lastIndex = taskName.lastIndexOf('.');
    if (lastIndex !== -1) {
      return taskName.slice(0, lastIndex) + taskName.slice(lastIndex + 1);
    }
    return taskName;
  }

  const renderTasks = () => {
    if(currentStory === undefined) return;

    return stories[currentStory].tasks
      && stories[currentStory].tasks.map((task, taskIndex) => {
        const renderSentence = taskIndex < 1 ? 'So' : '';
        const renderAnd = taskIndex !== stories[currentStory].tasks.length - 1 ? ' and ' : '';
        const renderFullStop = taskIndex === stories[currentStory].tasks.length - 1 ? '.' : '';
        return(<span key={createKey(task + uuid())}>
          {renderSentence} <b>{removeLastFullStop(task.name)}</b>
          {renderAnd}
          {renderFullStop}
        </span>)
      });
    }

  const renderQuestionAndAnswers = () => {
    if(currentStory === undefined) return;

    return stories[currentStory].tasks
      && stories[currentStory].tasks.map((task, taskIndex) => {
        return(<span key={createKey(task + uuid())}>
          <div>{task.name}</div>
          <ul>
            {
              stories[currentStory].tasks[taskIndex].questions &&
              stories[currentStory].tasks[taskIndex].questions.map((question, questionIndex) => {
                return (<li key={createKey(question + uuid())}>
                  {question.name}
                  <ul>
                    {
                      stories[currentStory].tasks[taskIndex].questions[questionIndex].answers &&
                      stories[currentStory].tasks[taskIndex].questions[questionIndex].answers.map((answer) => {
                        return (<li key={createKey(answer + uuid())}>
                          <pre style={{
                              wordWrap: 'break-word',
                              whiteSpace: 'pre-wrap'
                            }}>
                            {answer.name}
                            {answer.answered &&
                            (
                              <CheckCircleOutlineIcon style={{
                                color: '#20BF55',
                                height: '20px',
                                margin: '-2px 0 0 5px'
                              }}/>
                            )
                            }
                          </pre>
                        </li>)
                      })
                    }
                  </ul>
                </li>)
              })
            }
          </ul>
        </span>)
      });
    }

  return(<>
      {(stories.length > 0 && !showStoryInput && preview) &&
      (<>
        <h2 style={{
          margin: '0 0 25px 0'
        }}>{getStoryNameWithStoryPoints()}</h2>
        <div style={{
          margin: '0 0 25px 0'
        }}>
          I want to <b>{getStoryName()}</b>.&nbsp;
            {renderTasks()}
          <div style={{
            margin: '25px 0 45px 0'
          }}>
            {renderQuestionAndAnswers()}
          </div>
        </div>
      </>)
      }
      {(stories.length > 0 && showStoryInput) && (
        <p style={{
          margin: '0 0 25px 0'
        }}>
          <em>
            New story preview will appear here.
          </em>
        </p>
      )}
      {stories.length < 1 && (
        <p style={{
          margin: '0 0 25px 0'
        }}>
          <em>
            Story preview will appear here.
          </em>
        </p>
      )}
    </>
  );
}

export default StoryPreview;