import React from 'react';       
import {
  Stack,
  Link
  } from '@mui/material';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const TaskContainer = ({props}) => {
  const {
    task,
    taskIndex,
    setEdit,
    setShowEditInput,
    renderTask,
    setShowQuestionInput,
    setCurrentTask,
    collapseId,
    handleCollapse,
    handleExpand
  } = props;

  const containerStyle = {
    margin: `${taskIndex === 0 ? '5px' : '25px'} 0 0 0`,
    display: 'flex'
  };

  return (<div style={containerStyle}>
    {collapseId.length > 0 &&
      (<AddIcon
        onClick={() => handleExpand(taskIndex)}
        style={{
          cursor: 'pointer',
          margin: '0 3px 0 0'
        }}
      />)
    }
    {collapseId.length < 1 &&
      (<RemoveIcon 
        onClick={() => handleCollapse(taskIndex)}
        style={{
          cursor: 'pointer',
          margin: '0 3px 0 0'
        }}
      />)
    }
    {renderTask(task, taskIndex)}
    &nbsp;
    <Stack style={{
      display: 'inline'
    }} spacing={0.5} direction="row">
      <Link
        component="button"
        underline="hover"
        onClick={() => {
          setEdit({
            type: 'task',
            taskId: taskIndex,
            questionId: undefined,
            answerId: undefined,
            newValue: task.name
          });
          setShowEditInput(true);
        }}
        style={{
          fontSize: 15
        }}
      >
        Edit
      </Link>
      <span style={{
        fontSize: 15
      }}>or</span>
      <Link
        component="button"
        underline="hover"
        onClick={() => {
            setShowQuestionInput(taskIndex);
            setCurrentTask(taskIndex);
          }
        }
        style={{
          fontSize: 15
        }}
      >
        Question +
      </Link>
    </Stack>
  </div>);
}

export default TaskContainer;