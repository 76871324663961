import React from 'react';
import SimpleLink from '../../../common/SimpleLink';

const StoryMenu = ({props}) => {
	const {
    setCurrentStory,
    setShowStoryInput,
    style
  } = props;
	return(<SimpleLink
        events={() => {
          setCurrentStory(undefined);
          setShowStoryInput(true);
        }}
        style={style}
        >
        Add Story +
    </SimpleLink>);
}

export default StoryMenu;