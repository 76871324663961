import React from 'react';       
import {
  TextField,
  MenuItem,
  Stack
  } from '@mui/material';
import { Dropdown } from 'semantic-ui-react';
import UpdateStory from './UpdateStory';
import SimpleLink from '../../../common/SimpleLink';
import 'semantic-ui-css/components/dropdown.min.css';
import 'semantic-ui-css/components/transition.min.css';
import 'semantic-ui-css/components/label.min.css';

const roleOptions = [
  {
    key: 'Dev',
    text: 'Dev',
    value: 'Dev',
    label: { color: 'blue', empty: true, circular: true }
  },
  {
    key: 'Design',
    text: 'Design',
    value: 'Design',
    label: { color: 'yellow', empty: true, circular: true }
  },
  {
    key: 'None',
    text: 'None',
    value: null,
    label: { color: 'grey', empty: true, circular: true }
  }
]

const RoleDropdown = (props) => {
  const { handleRoles, role } = props;
  return (<Dropdown
      inline
      className="role-dropdown"
      options={roleOptions}
      text={role ? role : 'Role'}
      onChange={handleRoles}
    />);
}

const StoryContainer = ({props, children}) => {
  const {
    showEditStoryInput,
    setStory,
    setShowEditStoryInput,
    handleStoryChange,
    handleStoryNameChange,
    handleRoles,
    story,
    storyItem,
    storyIndex
  } = props;

  return (<>
    <div style={{
      margin: '0 0 3px 0',
      fontWeight: '500',
      fontSize: 17
    }}>
      <Stack
        spacing={0.5}
        direction="row"
        justifyContent="flex-start"
      >
        {!showEditStoryInput &&
          (
            <>
              <span>
                {storyItem.name}
              </span>
              <SimpleLink
                events={() => {
                  setStory(storyItem.name);
                  setShowEditStoryInput(true);
                }}
                style={{
                  fontSize: 15
                }}
              >
                Edit
              </SimpleLink>
              <span>|</span>
              <RoleDropdown handleRoles={handleRoles} role={storyItem.role}/>
            </>
          )
        }
        {showEditStoryInput &&
          (<UpdateStory props={{
            handleStoryChange,
            handleStoryNameChange,
            story,
            storyIndex
          }} />)
        }
      </Stack>
    </div>
    <div style={{
      margin: '0 0 3px 0'
    }}>
      <TextField
        select
        variant="outlined"
        defaultValue="1"
        label="Helper question"
        size="small"
        InputLabelProps={{
          shrink: true,
        }}
        style={{
          fontSize: 15,
          width:  400,
          margin: '15px 0 15px 0'
        }}
      >
        <MenuItem value="1">Q. What does it need to do?</MenuItem>
        <MenuItem value="2">Q. How does it fit together with all the other stories?</MenuItem>
        <MenuItem value="3">Q. What does the user interface look like?</MenuItem>
        <MenuItem value="4">Q. What data do you need and where does it come from?</MenuItem>
      </TextField>
    </div>
    {children}
  </>);
}

export default StoryContainer;