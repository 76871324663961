import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { db } from '../../../../firebase';
import { ref, set } from 'firebase/database';
import { useLocation } from 'react-router-dom';
import {
  Grid,
  FormGroup,
  TextField,
  Button,
  Link
  } from '@mui/material';
import SimpleLink from '../../../common/SimpleLink';
import EditInput from '../common/EditInput';
import createKey from '../../../../utils';

const StoryPointsTabPanel = ({stories, currentStory}) => {
  const [criteria, setCriteria] = useState('');
  const [showEditInput, setShowEditInput] = useState(undefined);
  const [showCriteriaInput, setShowCriteriaInput] = useState(undefined);
  const [currentTask, setCurrentTask] = useState(undefined);
  const [currentAc, setCurrentAc] = useState(undefined);
  const [edit, setEdit] = useState({
    newValue: ''
  });
  const location = useLocation();

  const handleAcceptableCriteria = (e) => {
    const { value } = e.target;
    setCriteria(value);
  }

  const handleEdit = (event) => {
    const {value} = event.target;
    setEdit({
      newValue: value
    });
  }

  const handleUpdate = () => {
    console.log(edit);
    const newStories = [...stories];
    if(newStories.length > 0) {
      newStories[currentStory || 0].tasks[currentTask]
      .ac[currentAc].name = edit.newValue;
      setShowEditInput(false);
    }
    const getCurrentPathName = location.pathname.replace('/story/', '');
    set(ref(db, `linkStories/${getCurrentPathName}`), newStories);
  }

  const handleDelete = () => {
    const newStories = [...stories];
    delete newStories[currentStory].tasks[currentTask]
    .ac[currentAc];
    setEdit('');
    setShowEditInput(false);
    const getCurrentPathName = location.pathname.replace('/story/', '');
    set(ref(db, `linkStories/${getCurrentPathName}`), newStories);
  }

  const addAcceptableCriteria = (taskId) => {
    const newStories = [...stories];

    if (!newStories[currentStory || 0].tasks[taskId].ac) {
      newStories[currentStory || 0].tasks[taskId].ac = [];
    }

    newStories[currentStory || 0].tasks[taskId].ac.push({
      name: criteria
    });

    if(newStories.length > 0) {
      const getCurrentPathName = location.pathname.replace('/story/', '');
      set(ref(db, `linkStories/${getCurrentPathName}`), newStories);
      setCriteria('');
    }
  }

  const renderStory = () => {
    if(stories && stories.length < 1) return;

    return stories.map((storyItem, storyIndex) => {
      if ((currentStory || 0) === storyIndex) {
        return <div key={createKey(storyItem.name + storyIndex)}>
          <Grid container spacing={0} alignItems="center">
            <Grid>
              <div style={{
                fontSize: 17,
                fontWeight: 500,
                color: 'rgba(0, 0, 0, 0.87)',
                margin: '17px 15px 16.5px 0'
              }}>
                {storyItem.name}
              </div>
            </Grid>
          </Grid>
          <div style={{
              fontSize: 17,
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.87)'
            }}>
            Tasks
          </div>
          <ol>
          {
            storyItem.tasks.map((taskItem, taskIndex) => {
              return (<li
                key={createKey(taskItem.name + taskIndex)}
                style={{
                  margin: taskIndex > 0 ? '15px 0 0 0' : '0'
                }}
              >
                  {taskItem.name}
                  { ' '}
                  <SimpleLink
                    events={() => {
                      setShowCriteriaInput(taskIndex);
                    }}
                    style={{
                      lineHeight: 'initial'
                    }}
                  >
                    Criteria +
                  </SimpleLink>
                  {
                    storyItem.tasks[taskIndex].ac
                    && storyItem.tasks[taskIndex].ac.map((acItem, acIndex) => {
                      return (<ul style={{
                        margin: '0',
                        fontSize: 16
                      }}>
                        <li>
                          <span>{acItem.name}</span>
                          <span>&nbsp;</span>
                          <Link
                            component="button"
                            underline="hover"
                            onClick={() => {
                              setCurrentTask(taskIndex);
                              setCurrentAc(acIndex);
                              setEdit({
                                newValue: acItem.name
                              });
                              setShowEditInput(true);
                            }}
                            style={{
                              position: 'relative',
                              top: -1,
                              fontSize: 15,
                              height: 18,
                              lineHeight: 'initial'
                            }}
                          >
                            Edit
                          </Link>
                        </li>
                      </ul>)
                    })
                  }
                  {showCriteriaInput === taskIndex &&
                    (<div style={{
                      margin: '0 0 15px 0'
                    }}>
                      <FormGroup row>
                        <TextField
                          label="It needs to..."
                          onChange={handleAcceptableCriteria}
                          value={criteria}
                          size="small"
                          style={{
                            margin: '0 10px 0 0'
                          }}
                        />
                        <Button
                          variant="outlined"
                          onClick={() => {
                            addAcceptableCriteria(taskIndex)
                          }}
                          style={{
                            backgroundColor: '#fff'
                          }}
                        >
                          Add
                        </Button>
                      </FormGroup>
                    </div>)
                  }
                </li>)
            })
          }
          </ol>
        </div>
      }
    });
  }

  useEffect(() => {
    let isSubscribed;
    //
    return () => isSubscribed = false;
  }, []);

  return (
    <div className="row">
      <div className="col-6">
        {renderStory()}
        {showEditInput &&
          (<EditInput props={{
            handleEdit,
            handleUpdate,
            handleDelete,
            edit 
          }}/>)
        }
      </div>
    </div>
  );
}

StoryPointsTabPanel.propTypes = {
  stories: PropTypes.array,
  currentStory: PropTypes.number
};

const mapStateToProps = (state) => {
  return {
    stories: state.stories,
    currentStory: state.currentStory
  };
};

export default connect(mapStateToProps, null)(StoryPointsTabPanel);
