import React from 'react';
import {
  Button
  } from '@mui/material';

const UpdateStory = ({props}) => {
	const {handleStoryChange, handleStoryNameChange, story, storyIndex} = props;
	return(<>
		<input type="text" onChange={handleStoryChange} value={story}/>
		<Button
		  size="small"
		  variant="outlined"
		  onClick={() => handleStoryNameChange(storyIndex)}
		>
		  UPDATE
		</Button>
	</>);
}

export default UpdateStory;