import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Grid,
  TextField,
  MenuItem
  } from '@mui/material';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { db } from '../../../../firebase';
import { ref, set } from 'firebase/database';
import createKey from '../../../../utils';

const StoryPointsTabPanel = ({stories, currentStory}) => {
  const location = useLocation();

  const handleSprintPoints = (e) => {
    if(stories.length > 0) {
      const { value } = e.target;
      const newStory = {...stories};
      const getCurrentPathName = location.pathname.replace('/story/', '');

      newStory[currentStory].storyPoints = value;
      set(ref(db, `linkStories/${getCurrentPathName}`), newStory);
    }
    if(stories.length > 0) {
      toast.info('Updated');
    }
  }

  const renderStory = () => {
    if(stories && stories.length < 1) return;

    return stories.map((storyItem, storyIndex) => {
      if ((currentStory || 0) === storyIndex) {
        return <div key={createKey(storyItem.name + storyIndex)}>
          <Grid container spacing={0} alignItems="center">
            <Grid>
              <span style={{
                fontSize: 17,
                fontWeight: 500,
                color: 'rgba(0, 0, 0, 0.87)',
                margin: '0 15px 0 0'
              }}>
                {storyItem.name}
              </span>
            </Grid>
            <Grid>
              <TextField
                select
                variant="standard"
                defaultValue={storyItem.storyPoints || '0'}
                size="small"
                onChange={handleSprintPoints}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{
                  fontSize: 15,
                  margin: '15px 0 15px 0'
                }}
              >
                <MenuItem value="0">0</MenuItem>
                <MenuItem value="0.5">0.5</MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="8">8</MenuItem>
                <MenuItem value="13">13</MenuItem>
                <MenuItem value="20">20</MenuItem>
                <MenuItem value="40">40</MenuItem>
                <MenuItem value="100">100</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <div style={{
              fontSize: 17,
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.87)'
            }}>
            Tasks
          </div>
          <ol>
          {
            storyItem.tasks.map((taskItem, taskIndex) => {
              return (<li key={createKey(taskItem.name + taskIndex)}>
                  {taskItem.name}
                </li>)
            })
          }
          </ol>
        </div>
      }
    });
  }

  useEffect(() => {
    let isSubscribed;
    //
    return () => isSubscribed = false;
  }, []);

  return (
    <>
      {renderStory()}
    </>
  );
}

StoryPointsTabPanel.propTypes = {
  stories: PropTypes.array,
  currentStory: PropTypes.number
};

const mapStateToProps = (state) => {
  return {
    stories: state.stories,
    currentStory: state.currentStory
  };
};

export default connect(mapStateToProps, null)(StoryPointsTabPanel);
