import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import {
  Box,
  Button,
	Toolbar,
  Tooltip,
  Drawer,
  Stack,
  Link,
  IconButton
	} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { TreeView, TreeItem } from '@mui/lab';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import { ToastContainer } from 'react-toastify';
import { StoriesTabs, GuidelinesTabs } from './components/tabs/index';
import createKey from './utils';
import 'react-toastify/dist/ReactToastify.css';
import './styles/App.css';

const drawerWidth = 280;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

function App ({stories, loadStories, saved}) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDrawer = () => {
    setOpen(!open);
  }

  const handleSave = () => {
    if (stories.length > 0) {
      dispatch({ type: 'SAVE_STORIES', save: true });
    }
  }

  const handleCopy = () => {
    dispatch({ type: 'COPY_STORIES', copy: true });
  }

  const updateCurrentStory = (storyId) => {
    dispatch({ type: 'UPDATE_CURRENT_STORY', currentStory: storyId });
  }

  const renderTree = () => {
    if(!Array.isArray(stories)
      || stories.length < 1) return <p style={{
            fontSize: 14
          }}>
            <em>
              Stories will appear here.
            </em>
          </p>; 

    return (<>
      <TreeView
          aria-label=""
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{ height: 165, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
        >
        {stories && stories.map((story, storyIndex) => {
          let roleColor = 'transparent';
          if (story.role && story.role === 'Dev') {
            roleColor = 'blue';
          }
          if (story.role && story.role === 'Design') {
            roleColor = 'red';
          }
          return (<TreeItem nodeId={createKey(`${story.name}${storyIndex}`)} key={createKey(`${story.name}${storyIndex}`)} label={
            <div style={{
              display: 'flex'
            }}>
              <span className={`role-status ${roleColor}`} style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                maxWidth: '115px'
              }}>
                {story.name}
              </span>
              &nbsp;
              |
              &nbsp;
              <Link
                component="button"
                underline="hover"
                onClick={() => {updateCurrentStory(storyIndex)}}
              >
                Edit
              </Link>
            </div>
          }>
            {story.tasks && story.tasks.map((task, taskIndex) => {
              return (<TreeItem nodeId={createKey(`${task.name}${taskIndex}`)} key={createKey(`${task.name}${taskIndex}`)} label={
                <span style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  maxWidth: '145px'
                }}>
                  {task.name}
                </span>
              }/>)
            })}
          </TreeItem>
          )
        })}
      </TreeView>
    </>)
  }

  const resetApp = () => {
    dispatch({ type: 'RESET_STORIES', reset: true });
    navigate('/');
  }

  useEffect(() => {
    let isSubscribed = true;
    if (stories && stories.length > 0) {
      return () => isSubscribed = false;
    }
    return () => isSubscribed = false;
  }, [stories]);

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed"
        open={open}
        component="nav"
        style={{
          backgroundColor: '#fff',
          color: '#3C3C3B',
          boxShadow: 'initial'
      }}>
        <Toolbar>
          <Box
            sx={{ flexGrow: 1 }}
          >
            <Stack spacing={1} direction="row" style={{
              height: '32px'
            }}>  
              <img src="/logo.svg" style={{ height: 32 }} />
              <Tooltip title="Add a story to save">
                <span style={{margin: 0}}>
                  <Button
                    variant="text"
                    size="small"
                    onClick={handleSave}
                    disabled={saved || loadStories}
                    style={{
                      margin: '0 0 0 20px'
                    }}
                  >Save</Button>
                </span>
              </Tooltip>
              <Tooltip title="Copy current story">
                <span style={{margin: '0 0 0 8px'}}>
                  <Button
                    variant="text"
                    size="small"
                    onClick={handleCopy}
                    disabled={!loadStories}
                  >Copy</Button>
                </span>
              </Tooltip>
              <Tooltip title="Not avail. in alpha">
                <Button
                  variant="text"
                  size="small"
                >Sync</Button>
              </Tooltip>
            </Stack>
          </Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawer}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawer}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <div style={{
          padding: 20
        }}>
          <h4 style={{
            fontSize: 15,
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
          }}>STORIES</h4>
          {renderTree()}
          <hr />
          <h4 style={{
            fontSize: 15,
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
          }}>CONNECT</h4>
          <p style={{
            fontSize: 14
          }}>
            <em>
              Connect your fav sprint platforms.
            </em>
          </p>
        </div>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <div>
          <StoriesTabs/>
          <GuidelinesTabs/>
          <ToastContainer/>
        </div>
      </Main>
    </Box>
  );
}

App.propTypes = {
  stories: PropTypes.array,
  loadStories: PropTypes.bool,
  saved: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    stories: state.stories,
    loadStories: state.loadStories,
    saved: state.saved
  };
};

export default connect(mapStateToProps, null)(App);
