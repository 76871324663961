import React from 'react';
import {
  Stack,
  Divider,
  Button
  } from '@mui/material';

const styles = {
  stack: {
    width: '75%',
    margin: '5px 0 0 0'
  },
  divider: {
    width: '75%',
    pt: 3,
    pb: 3
  },
  smallText: {
    fontSize: 14
  },
  textarea: {
    width: '75%',
    height: 125
  }
};

const EditInput = ({props}) => {
	const {
    handleEdit,
    handleUpdate,
    handleDelete,
    edit
  } = props;

	return(<>
    <Divider textAlign="left" sx={styles.divider}>
      <span style={styles.smallText}>EDIT</span>
    </Divider>
    <textarea
      onChange={handleEdit}
      value={edit && edit.newValue}
      style={styles.textarea}
    />
    <Stack
      spacing={2}
      direction="row"
      justifyContent="flex-end"
      style={styles.stack}
    >
      <Button
        size="small"
        variant="contained"
        onClick={handleUpdate}
      >
        UPDATE
      </Button>
      <Button
        size="small"
        color="error"
        variant="contained"
        onClick={handleDelete}
      >
        DELETE
      </Button>
    </Stack>
		</>);
}

export default EditInput;