import React from 'react';
import {
  Button
  } from '@mui/material';

const AnswerInput = ({props}) => {
	const {handleAnswerChange, answer, addAnswer} = props;
	return(<div style={{
    margin: '0 0 0 30px'
  }}>
	    <input type="text" onChange={handleAnswerChange} placeholder="Add answer" value={answer} />
	    &nbsp;
      <Button
        variant="outlined"
        size="small"
        onClick={addAnswer}
      >
      	Add
      </Button>
		</div>);
}

export default AnswerInput;