import React from 'react';
import {
  Link,
  Stack
  } from '@mui/material';

const Answer = ({props}) => {
	const {
		answer,
		taskIndex,
		questionIndex,
		answerIndex,
		handleAnswered,
		setEdit,
		setShowEditInput
	} = props;

  return (
		<div style={{
			display: 'flex'
		}}>
		  A.
		  &nbsp;
		  <span style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        maxWidth: '150px'
      }}>
		  	{answer.name}
		  </span>
		  &nbsp;
			<Stack style={{
        display: 'inline'
      }} spacing={0.5} direction="row">
	      <Link
	        component="button"
	        underline="hover"
        	onClick={() => {
	          setEdit({
	            type: 'answer',
	            taskId: taskIndex,
	            questionId: questionIndex,
	            answerId: answerIndex,
	            newValue: answer.name
	          });
	          setShowEditInput(true);
	        }}
	        style={{
	          fontSize: 15
	        }}
	      >
	        Edit
	      </Link>
        <span style={{
          fontSize: 15
        }}>or</span>
        <Link
          component="button"
          underline="hover"
          onClick={() => handleAnswered({
            taskIndex,
            questionIndex,
            answerIndex
          })}
          style={{
            fontSize: 15,
			fontWeight: `${answer.answered ? 'bold' : 'normal'}`
          }}
        >
          Answered
        </Link>
	    </Stack>
		</div>
  );
}

export default Answer;