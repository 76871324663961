// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAFUZX7HCkeEEh2EwBRnQbOC2g2PkTmKwE',
  authDomain: 'sprint-planner-3d84d.firebaseapp.com',
  projectId: 'sprint-planner-3d84d',
  storageBucket: 'sprint-planner-3d84d.appspot.com',
  messagingSenderId: '697680882484',
  appId: '1:697680882484:web:845782cb6edb90a4d49361'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getDatabase(app);