/*
  Setting up middleware boilerplate functions here.
*/

// Assume each action knows about the full state-tree
const enhanceAction = ({ getState }) => next => action => {
	const currentState = getState();
	return next({ currentState, ...action });
};

// Log actionType, action and newState
const logger = ({ getState }) => next => action => {
	const returnValue = next(action);
	if (window.DEBUG) {
		console.log([`Action: ${action.type}`, action, getState()]);
	}
	return returnValue;
};

export default [enhanceAction, logger];
