import * as React from 'react';
import Link from '@mui/material/Link';

const Page1 = () => {
  return (
    <div style={{
      fontSize: 15
    }}>
        <p>
          <b>Welcome.</b> In the near future you can come here for 
          sprint planning resources.
        </p>
        <p>
          If you would like to contribute,&nbsp;
          <Link
            underline="hover"
            color="inherit"
            target="_blank"
            href="https://www.linkedin.com/in/jamesstar/"
          >
            contact me on LI
          </Link>.
        </p>
    </div>
  );
}

export default Page1;