import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { db } from '../../../firebase';
import { ref, get } from 'firebase/database';
import { Card, CardContent, Box, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import StoryTabPanel from './stories';
import StoryPointsTabPanel from './storyPoints';
import AcceptanceCriteriaTabPanel from './acceptanceCriteria';

const StoriesTabs = ({stories}) => {
  const [tab, setTab] = useState('0');
  const [storiesLoadedByUrl, setStoriesLoadedByUrl] = useState([]);
  const [storiesLoadedByUrlComplete, setStoriesLoadedByUrlComplete] = useState(false);
  const location = useLocation();
  const { pathname } = location;

  const styles = {
    cardContent: {
      padding: '25px'
    },
    tabDivider: {
      width: '100%',
      borderBottom: 1,
      borderColor: 'divider'
    }
  }

  const handleTabs = (e, newValue) => {
    setTab(newValue);
  }

  useEffect(() => {
    let isSubscribed;
    if (pathname && pathname.indexOf('/story/') > -1
    && !storiesLoadedByUrlComplete) {
      const pathId = pathname.replace('/story/', '');
      const storyRef = ref(db, 'linkStories/' + pathId);
      get(storyRef).then((snapshot) => {
        const newStories = snapshot.val();
        setStoriesLoadedByUrl(newStories);
        setStoriesLoadedByUrlComplete(true);
      });
      return () => isSubscribed = false;
    }
  }, [stories]);

  return (
    <>
      <Card variant="outlined" style={{
        minHeight: 400
      }}>
        <CardContent style={styles.cardContent}>
          <TabContext value={tab}>
            <Box sx={styles.tabDivider}>
              <TabList onChange={handleTabs}>
                <Tab label="Stories" value="0"/>
                <Tab label="Story Points" value="1" disabled={!storiesLoadedByUrlComplete && stories.length < 1 ? true : false}/>
                <Tab label="Acceptance C." value="2" disabled={!storiesLoadedByUrlComplete && stories.length < 1 ? true : false}/>
                <Tab label="Timeline" value="3" disabled/>
                <Tab label="Results" value="4" disabled/>
              </TabList>
            </Box>
            <TabPanel value="0" sx={{ p: 0, pt: 3 }}>
              <StoryTabPanel props={{
                storiesLoadedByUrl,
                setStoriesLoadedByUrl
              }}/>
            </TabPanel>
            <TabPanel value="1" sx={{ p: 0, pt: 3 }}>
              <StoryPointsTabPanel />
            </TabPanel>
            <TabPanel value="2" sx={{ p: 0, pt: 3 }}>
              <AcceptanceCriteriaTabPanel />
            </TabPanel>
          </TabContext>
        </CardContent>
      </Card>
    </>
  );
}

StoriesTabs.propTypes = {
  stories: PropTypes.array
};

const mapStateToProps = (state) => {
  return {
    stories: state.stories
  };
};

export default connect(mapStateToProps, null)(StoriesTabs);
