import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import { db } from '../../../../firebase';
import { ref, set, push } from 'firebase/database';
import { Box, Stack, Button } from '@mui/material';
import { toast } from 'react-toastify';
import SimpleLink from '../../../common/SimpleLink';
import StoryContainer from './StoryContainer';
import TaskContainer from './TaskContainer';
import QuestionsContainer from './QuestionsContainer';
import StoryPreview from './StoryPreview';
import StoryInput from './StoryInput';
import QuestionInput from './QuestionInput';
import AnswerInput from './AnswerInput';
import StoryMenu from './StoryMenu';
import Answer from './Answer';
import NewTask from './NewTask';
import EditInput from '../common/EditInput';
import createKey from '../../../../utils';
import initialState from '../initialState';
import './styles.css';

const StoryContainerWrapper = ({children, props}) => {
  const {
    showEditStoryInput,
    setStory,
    setShowEditStoryInput,
    handleStoryChange,
    handleStoryNameChange,
    handleRoles,
    story,
    currentStory,
    stories
  } = props;
  return stories.map((storyItem, storyIndex) => {
    if (currentStory === storyIndex) {
      return (
        <StoryContainer
          key={createKey(storyItem.name + storyIndex)}
          props={{
            showEditStoryInput,
            setStory,
            setShowEditStoryInput,
            handleStoryChange,
            handleStoryNameChange,
            handleRoles,
            story,
            storyItem,
            storyIndex
          }}>
            {children}
        </StoryContainer>
      )
    }
  });
}

const StoriesTabPanel = ({saveClicked, copy, props}) => {
  const [stories, setStories] = useState(initialState.emptyArray);
  const [story, setStory] = useState(initialState.empty);
  const [task, setTask] = useState(initialState.empty);
  const [question, setQuestion] = useState(initialState.empty);
  const [answer, setAnswer] = useState(initialState.empty);
  const [collapse, setCollapse] = useState(initialState.emptyArray);
  const [currentStory, setCurrentStory] = useState(initialState.undefined);
  const [currentTask, setCurrentTask] = useState(initialState.undefined);
  const [currentQuestion, setCurrentQuestion] = useState(initialState.undefined);
  const [preview, setPreview] = useState(initialState.false);
  const [showTaskInput, setShowTaskInput] = useState(initialState.false);
  const [showStoryInput, setShowStoryInput] = useState(initialState.false);
  const [showEditStoryInput, setShowEditStoryInput] = useState(initialState.false);
  const [showQuestionInput, setShowQuestionInput] = useState(initialState.undefined);
  const [showAnswerInput, setShowAnswerInput] = useState(initialState.undefined);
  const [showEditInput, setShowEditInput] = useState(initialState.undefined);
  const [edit, setEdit] = useState({
    type: initialState.undefined,
    taskId: initialState.undefined,
    questionId: initialState.undefined,
    answerId: initialState.undefined,
    newValue: initialState.empty
  });
  const [save, setSave] = useState(initialState.false);
  const [storiesLoaded, setStoriesLoaded] = useState(initialState.false);
  const reset = useSelector(state => state.reset);
  const tempStories = useSelector(state => state.stories);
  const updatedCurrentStory = useSelector(state => state.currentStory);
  const { storiesLoadedByUrl, setStoriesLoadedByUrl } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const styles = {
    bottomMenu: {
      display: 'block',
      margin: '25px 0 0 0'
    }
  }

  const unique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  const addStory = () => {
    const newStories = [...stories];
    newStories.push({
      name: story,
      created: Date.now(),
      tasks: []
    });
    setStory('');
    setStories(newStories);
    setCurrentStory(newStories.length - 1);
    setShowStoryInput(false);
    setShowEditInput(false);
    dispatch({ type: 'RESET_STORIES', reset: false });
    if (storiesLoaded) {
      handleUpdate();
    }
  }

  const addTask = () => {
    const newStories = [...stories];
    if (!newStories[currentStory].tasks) {
      newStories[currentStory].tasks = [];
    }
    newStories[currentStory].tasks.push({
      name: task,
      questions: []
    });
    setTask('');
    setStories(newStories);
    setCurrentTask(newStories[currentStory].tasks.length - 1);
    setShowTaskInput(false);
    if (storiesLoaded) {
      handleUpdate();
    }
  }

  const addQuestion = () => {
    const newStories = [...stories];
    if (!newStories[currentStory].tasks[currentTask].questions) {
      newStories[currentStory].tasks[currentTask].questions = [];
    }
    newStories[currentStory].tasks[currentTask].questions.push({
      name: question,
      answers: []
    });
    setQuestion('');
    setStories(newStories);
    setShowQuestionInput(undefined);
    if (storiesLoaded) {
      handleUpdate();
    }
  }

  const addAnswer = () => {
    const newStories = [...stories];
    if (!newStories[currentStory].tasks[currentTask].questions[currentQuestion].answers) {
      newStories[currentStory].tasks[currentTask].questions[currentQuestion].answers = [];
    }
    newStories[currentStory].tasks[currentTask].questions[currentQuestion].answers.push({
      name: answer
    });
    setAnswer('');
    setStories(newStories);
    setShowAnswerInput(undefined);
    if (storiesLoaded) {
      handleUpdate();
    }
  }

  const showHidePreview = () => {
    setPreview(!preview);
  }

  const handleStoryChange = (event) => {
    const {value} = event.target;
    setStory(value);
  }

  const handleStoryNameChange = () => {
    const newStories = [...stories];
    newStories[currentStory].name = story;
    setStories(newStories);
    setShowEditStoryInput(false);
    if(save || storiesLoaded) {
      const getCurrentPathName = location.pathname.replace('/story/', '');
      set(ref(db, `linkStories/${getCurrentPathName}`), stories);
    }
    toast.info('Updated');
  }

  const handleTaskChange = (event) => {
    const {value} = event.target;
    setTask(value);
  }

  const handleQuestionChange = (event) => {
    const {value} = event.target;
    setQuestion(value);
  }

  const handleAnswerChange = (event) => {
    const {value} = event.target;
    setAnswer(value);
  }

  const handleAnswered = ({
    taskIndex,
    questionIndex,
    answerIndex
  }) => {
    if(stories.length > 0 && (save || storiesLoaded)) {
      const newStories = [...stories];
      newStories[currentStory].tasks[taskIndex].questions[questionIndex].answers.map((answer, index) => {
        if (index === answerIndex) {
          newStories[currentStory].tasks[taskIndex].questions[questionIndex].answers[index].answered = true;
        } else {
          newStories[currentStory].tasks[taskIndex].questions[questionIndex].answers[index].answered = null;
        }
      });
      setStories(newStories);
      const getCurrentPathName = location.pathname.replace('/story/', '');
      set(ref(db, `linkStories/${getCurrentPathName}`), newStories);
    }
  }

  const handleEdit = (event) => {
    const {value} = event.target;
    const newEdit = {...edit};
    const newStories = [...stories];
    if (edit.type === 'task') {
      newStories[currentStory].tasks[newEdit.taskId].name = value;
    }
    if (edit.type === 'question') {
      newStories[currentStory].tasks[newEdit.taskId]
      .questions[newEdit.questionId].name = value;
    }
    if (edit.type === 'answer') {
      newStories[currentStory].tasks[newEdit.taskId]
        .questions[newEdit.questionId]
        .answers[newEdit.answerId].name = value;
    }
    if (edit.type === 'task'
    || edit.type === 'question'
    || edit.type === 'answer') {
      newEdit.newValue = value;
      setEdit(newEdit);
      setStories(newStories);
    }
  }

  const handleUpdate = () => {
    if(stories.length > 0 && (save || storiesLoaded)) {
      const getCurrentPathName = location.pathname.replace('/story/', '');
      set(ref(db, `linkStories/${getCurrentPathName}`), stories);
    }
    if(stories.length > 0) {
      toast.info('Updated');
      setShowEditInput(false);
    }
  }

  const handleRoles = (e, { value }) => {
    // first time before save
    if(stories.length > 0 && (!save || !storiesLoaded)) {
      const newStories = [...stories];
      newStories[currentStory].role = value;
      setStories(newStories);
    }
    if(stories.length > 0 && (save || storiesLoaded)) {
      const newStories = [...stories];
      newStories[currentStory].role = value;
      setStories(newStories);
      const getCurrentPathName = location.pathname.replace('/story/', '');
      set(ref(db, `linkStories/${getCurrentPathName}`), newStories);
    }
  }

  const handleDelete = () => {
    const newEdit = {...edit};
    const newStories = [...stories];
    if (edit.type === 'task') {
      delete newStories[currentStory].tasks[newEdit.taskId];
    }
    if (edit.type === 'question') {
      delete newStories[currentStory].tasks[newEdit.taskId]
      .questions[newEdit.questionId];
    }
    if (edit.type === 'answer') {
      delete newStories[currentStory].tasks[newEdit.taskId]
        .questions[newEdit.questionId]
        .answers[newEdit.answerId];
    }
    if (edit.type === 'task'
    || edit.type === 'question'
    || edit.type === 'answer') {
      newEdit.newValue = '';
      setEdit(newEdit);
      setStories(newStories);
      setShowEditInput(false);
      if (save || storiesLoaded) {
        const getCurrentPathName = location.pathname.replace('/story/', '');
        set(ref(db, `linkStories/${getCurrentPathName}`), newStories);
      }
    }
  }

  const handleCollapse = (taskId) => {
    let newCollapse = [...collapse];
    newCollapse.push(taskId);
    newCollapse = newCollapse.filter(unique);
    setCollapse(newCollapse);
  }

  const handleExpand = (taskId) => {
    let newCollapse = [...collapse];
    newCollapse = newCollapse.filter((item) => {
      return item !== taskId
    })
    setCollapse(newCollapse);
  }

  const handleCollapseAllOnLoad = (data) => {
    let newCollapse = [];
    if (data[updatedCurrentStory || 0].tasks
      && data[updatedCurrentStory || 0].tasks.length > 0) {
        data[updatedCurrentStory || 0].tasks.map((undefined, taskIndex) => {
          newCollapse.push(taskIndex);
        });
        setCollapse(newCollapse);
    }
  }

  const handleCollapseAll = () => {
    let newCollapse = [];
    stories[currentStory].tasks.map((undefined, taskIndex) => {
      newCollapse.push(taskIndex);
    });
    newCollapse = newCollapse.filter(unique);
    setCollapse(newCollapse);
  }

  const handleExpandAll = () => {
    setCollapse([]); 
  }

  /*

  - linkStories
  - userStories
  - users

  */

  const handleCopy = () => {
    if(stories.length > 0) {
      const key = push(ref(db, 'linkStories/'), stories).key;
      navigate(`/story/${key}`);
      dispatch({ type: 'COPY_STORIES', copy: false });
      toast.info('Copied');
    }
  }

  const handleSave = () => {
    if(stories.length > 0) {
      const key = push(ref(db, 'linkStories/'), stories).key;
      navigate(`/story/${key}`);
      setSave(true);
      dispatch({ type: 'SAVED_STORIES', saved: true });
      dispatch({ type: 'SAVE_STORIES', save: false });
    }
  }

  const renderStory = () => {
    if(currentStory === undefined) return;

    return (<StoryContainerWrapper props={{
        showEditStoryInput,
        setStory,
        setShowEditStoryInput,
        handleStoryChange,
        handleStoryNameChange,
        handleRoles,
        story,
        currentStory,
        stories
      }}>
      {
        stories.map((storyItem, storyIndex) => {
          if (currentStory === storyIndex) {
            return storyItem.tasks && storyItem.tasks.length > 0 &&
              (
                <div
                  key={createKey(storyItem.name + storyIndex)}>
                  {
                    storyItem.tasks.map((task, taskIndex) => {
                      const collapseId = collapse.filter((item) => {
                        return item === taskIndex
                      });
                      return <div key={createKey(task.name + taskIndex)}>
                        <div>
                          <TaskContainer props={{
                            task,
                            taskIndex,
                            setEdit,
                            setShowEditInput,
                            renderTask,
                            setShowQuestionInput,
                            setCurrentTask,
                            collapseId,
                            handleCollapse,
                            handleExpand
                          }} />
                        </div>
                        <div style={{
                          display: collapseId.length < 1 ? 'block' : 'none'
                        }}>
                          {
                            storyItem.tasks[taskIndex].questions
                            && storyItem.tasks[taskIndex].questions.map((question, questionIndex) => {
                              return <div key={createKey(question.name + questionIndex)}>
                                <QuestionsContainer props={{
                                  renderQuestion,
                                  setEdit,
                                  taskIndex,
                                  question,
                                  questionIndex,
                                  setShowEditInput,
                                  setShowAnswerInput,
                                  setCurrentTask,
                                  setCurrentQuestion
                                }}/>
                                <div style={{
                                  margin: '3px 0 0 30px'
                                }}>
                                  {
                                    storyItem.tasks[taskIndex].questions[questionIndex].answers
                                    && storyItem.tasks[taskIndex].questions[questionIndex].answers.map((answer, answerIndex) => {
                                      return (
                                        <Answer
                                          key={createKey(answer.name + answerIndex)}
                                          props={{
                                            answer,
                                            taskIndex,
                                            questionIndex,
                                            answerIndex,
                                            handleAnswered,
                                            setEdit,
                                            setShowEditInput
                                          }}
                                        />
                                      )
                                    })
                                  }
                                </div>
                                {currentTask === taskIndex &&
                                  questionIndex === showAnswerInput && (<AnswerInput props={{handleAnswerChange, answer, addAnswer}}/>)}
                              </div>
                            })
                          }
                        </div>
                        {taskIndex === showQuestionInput &&
                          (<QuestionInput props={{handleQuestionChange, question, addQuestion}}/>)}
                      </div>
                    })
                  }
                </div>
              )
          }
        })
      }
      {
        <>
          {showEditInput &&
            (<EditInput props={{
              handleEdit,
              handleUpdate,
              handleDelete,
              edit
            }}/>)
          }
          {showTaskInput &&
            (<NewTask props={{
              handleTaskChange,
              task,
              addTask
            }}/>)
          }
          <Stack spacing={0.5} direction="row" style={styles.bottomMenu}>
            <SimpleLink
              events={() => setShowTaskInput(true)}>Add task +</SimpleLink>
            <span>|</span>
            <SimpleLink
              events={handleCollapseAll}>Collapse</SimpleLink>
            <span>|</span>
            <SimpleLink
              events={handleExpandAll}>Expand all</SimpleLink>
          </Stack>
        </>
      }
    </StoryContainerWrapper>);
  }

  const renderTask = (task, index) => {
    if(index === 0) {
      return <>
          <em>
            [It needs to...]
          </em>
          &nbsp;
          <span style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            maxWidth: '200px'
          }}>
            {task.name}
          </span>
        </>;
    }
    return <>
        <em>
          [And...]
        </em>
        &nbsp;
        <span style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: 'inline-block',
          maxWidth: '200px'
        }}>
          {task.name}
        </span>
      </>;
  }

  const renderQuestion = (question) => {
    return <span style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        maxWidth: '150px'
      }}>
        Q. {question.name}
      </span>;
  }

  useEffect(() => {
    let isSubscribed;
    if(reset === true) {
      setStories(initialState.emptyArray);
      setStory(initialState.empty);
      setTask(initialState.empty);
      setQuestion(initialState.empty);
      setAnswer(initialState.empty);
      setCurrentStory(initialState.undefined);
      setCurrentTask(initialState.undefined);
      setCurrentQuestion(initialState.undefined);
      setShowTaskInput(initialState.false);
      setShowStoryInput(initialState.false);
      setShowQuestionInput(initialState.undefined);
      setShowAnswerInput(initialState.undefined);
      setStoriesLoaded(initialState.false);
      setCollapse(initialState.emptyArray);
      setSave(initialState.false);
      dispatch({ type: 'EMPTY_STORIES'});
      return () => isSubscribed = false;
    }
    if (storiesLoadedByUrl && storiesLoadedByUrl.length > 0 && !storiesLoaded) {
      setStories(storiesLoadedByUrl);
      setCurrentStory(0);
      setStoriesLoaded(true);
      dispatch({ type: 'LOAD_STORIES', loadStories: true });
      handleCollapseAllOnLoad(storiesLoadedByUrl);
      return () => isSubscribed = false;
    }
    if (stories.length < 1 && tempStories.length > 0) {
      // user has not saved their data
      setStories(tempStories);
      setCurrentStory(0);
      handleCollapseAllOnLoad(tempStories);
      return () => isSubscribed = false;
    }
    if (saveClicked && !save) {
      handleSave();
    }
    if (copy) {
      handleCopy();
    }
    // for tree menu
    if (stories && stories.length > 0
      && reset !== true) {
      dispatch({ type: 'UPDATE_STORIES', stories });
    }
    if (updatedCurrentStory !== null &&
      updatedCurrentStory !== currentStory) {
      setShowTaskInput(false);
      setShowStoryInput(false);
      setShowEditStoryInput(false);
      setShowQuestionInput(false);
      setShowAnswerInput(false);
      setShowEditInput(false);
      setCurrentStory(updatedCurrentStory);
      if (stories.length > 0) {
        handleCollapseAllOnLoad(stories);
      }
      dispatch({ type: 'UPDATE_CURRENT_STORY', currentStory: updatedCurrentStory });
    }
    return () => isSubscribed = false;
  }, [stories, copy, saveClicked, save, reset, storiesLoadedByUrl, updatedCurrentStory]);

  return (
    <>
      {stories.length < 1 &&
        (<div className="row">
          <div className="col-5">
            <div
              style={{
                  backgroundColor: '#fff',
                  borderRadius: '0',
                  padding: '24px 0 0 0'
                }}
              >
              <div
                className="d-flex align-items-center">
                {!showStoryInput &&
                (<>
                  <Button
                    variant="contained"
                    style={{
                      boxShadow: 'initial'
                    }}
                    onClick={() => {
                      setCurrentStory(undefined);
                      setShowStoryInput(true);
                    }}
                  >
                    Add Story +
                  </Button>
                  <div
                    style={{
                      margin: '0 0 0 30px'
                    }}
                    className="h6 fw-normal mb-0"
                  >
                    <p
                      className="mb-0"
                      style={{
                        fontSize: 15
                      }}>
                      <em>
                        Click to add new story.
                      </em>
                    </p>
                  </div>
                </>)
                }
                {showStoryInput &&
                  (<div>
                    <StoryInput
                      props={{
                        handleStoryChange,
                        story,
                        addStory
                      }}
                    />
                  </div>)
                }
              </div>
            </div>
          </div>
        </div>)
      }
      {stories.length > 0 &&
        (<div className="row">
          <div className="col">
            <StoryMenu props={{
              setCurrentStory,
              setShowStoryInput
            }}/>
            <Box sx={{ pt: 3 }}>
              {!showStoryInput &&
                (renderStory())
              }
              {showStoryInput &&
                (<StoryInput
                  props={{
                    handleStoryChange,
                    story,
                    addStory
                  }}
                />)
              }
            </Box>
          </div>
          <div className="col">
            {(currentStory !== undefined && !showStoryInput) &&
              (
                <div style={{
                  textAlign: 'right'
                }}>
                  <SimpleLink
                      events={showHidePreview}
                      >
                      {
                        preview && (<span>Hide </span>) || (<span>Show </span>)
                      }
                      Preview
                  </SimpleLink>
                </div>
              )
            }
            <StoryPreview props={{
              stories,
              showStoryInput,
              currentStory,
              preview
            }}/>
          </div>
        </div>)
      }
    </>
  );
}

StoriesTabPanel.propTypes = {
  saveClicked: PropTypes.bool,
  copy: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    saveClicked: state.save,
    copy: state.copy
  };
};

export default connect(mapStateToProps, null)(StoriesTabPanel);
