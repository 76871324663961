import React from 'react';
import {
  Button
  } from '@mui/material';

const TaskInput = ({props}) => {
	const {handleTaskChange, task, addTask} = props;
	return(<>
	    <input type="text" onChange={handleTaskChange} placeholder="Add task" value={task} />
	    &nbsp;
      <Button
        variant="outlined"
        size="small"
        onClick={addTask}
      >
      	Add
      </Button>
	  </>);
}

export default TaskInput;