import React from 'react';
import {
  Divider
  } from '@mui/material';
import TaskInput from './TaskInput';

const styles = {
	divider: {
		width: '75%',
		pt: 3,
		pb: 3
	},
	smallText: {
		fontSize: 14
	}
};

const NewTask = ({props}) => {
	const {handleTaskChange, task, addTask} = props;
	return(<>
    <Divider textAlign="left" sx={styles.divider}>
      <span style={styles.smallText}>NEW TASK</span>
    </Divider>
    <TaskInput props={{handleTaskChange, task, addTask}} />
  </>);
}

export default NewTask;