import React from 'react';
import {
  Link
  } from '@mui/material';

const SimpleLink = (props) => {
	const {children, events, style} = props;
	return(<>
		  <Link
        component="button"
        underline="hover"
        onClick={events}
        style={style}
      >
        {children}
      </Link>
		</>);
}

export default SimpleLink;