import React from 'react';
import {
  Button
  } from '@mui/material';

const QuestionInput = ({props}) => {
	const {handleQuestionChange, question, addQuestion} = props;
	return(<div style={{
    margin: '0 0 0 15px'
  }}>
      <input type="text" onChange={handleQuestionChange} placeholder="Add question" value={question} />
      &nbsp;
      <Button
        variant="outlined"
        size="small"
        onClick={addQuestion}
      >
      	Add
      </Button>
		</div>);
}

export default QuestionInput;