import React from 'react';
import {
  FormGroup,
  Button,
  TextField
  } from '@mui/material';

const StoryInput = ({props}) => {
	const {handleStoryChange, story, addStory} = props;
	return(<>
    <FormGroup row>
      <TextField
        label="Story name"
        onChange={handleStoryChange}
        value={story}
        size="small"
        style={{
          margin: '0 10px 0 0'
        }}
      />
      <Button
        variant="outlined"
        onClick={addStory}
        style={{
          backgroundColor: '#fff'
        }}
      >
        Add
      </Button>
    </FormGroup>
  </>);
}

export default StoryInput;