import React, { useState, useEffect } from 'react';
import { Card, CardContent, Box, Tab, Stack } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import SimpleLink from '../../common/SimpleLink';
import Page1 from './resources/Page1';

const styles = {
  cardContent: {
    padding: '25px'
  }
}

const GuidelinesTabs = () => {
  const [resource, setResource] = useState(undefined);

  const navigateResourceLink = (pageIndex) => {
    setResource(pageIndex);
  }

  return (
    <>
      <Card variant="outlined" style={{
        margin: '24px 0 0 0',
        minHeight: 400
      }}>
        <CardContent style={styles.cardContent}>
          <div className="row">
            <div className="col-4 no-gutter">
              <h3>Guidelines</h3>
              {resource === undefined &&
                <>
                  <p>
                    Sprint planning content and resources.
                  </p>
                  <ol style={{
                    fontSize: 15
                  }}>
                    <li>
                      <SimpleLink events={() => {
                        navigateResourceLink(0);
                      }}>What is sprint planning?</SimpleLink>
                    </li>
                    <li>
                      <SimpleLink events={() => {
                        navigateResourceLink(0);
                      }}>Sprint planing best practices</SimpleLink>
                    </li>
                    <li>
                      <SimpleLink events={() => {
                        navigateResourceLink(0);
                      }}>Sprint reviews</SimpleLink>
                    </li>
                    <li>
                      <SimpleLink events={() => {
                        navigateResourceLink(0);
                      }}>Retrospectives</SimpleLink>
                    </li>
                    <li>
                      <SimpleLink events={() => {
                        navigateResourceLink(0);
                      }}>Backlogs</SimpleLink>
                    </li>
                    <li>
                      <SimpleLink events={() => {
                        navigateResourceLink(0);
                      }}>More....</SimpleLink>
                    </li>
                  </ol>
                </>
              }
              {resource !== undefined &&
                (<>
                  <div role="presentation" onClick={(event) => {
                    event.preventDefault();
                    setResource(undefined);
                  }} style={{
                    margin: '0 0 20px 0'
                  }}>
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link underline="hover" color="inherit" href="/">
                        Guidelines
                      </Link>
                      <Typography color="text.primary">Placeholder</Typography>
                    </Breadcrumbs>
                  </div>
                  <Page1/>
                </>)
              }
            </div>
            {/*
            <div className="col">
              <div style={{
                textAlign: 'right'
              }}>
                <SimpleLink
                    events={() => {}}
                    >
                    Hide
                </SimpleLink>
              </div>
            </div>
            */}
          </div>
        </CardContent>
      </Card>
    </>
  );
}

export default GuidelinesTabs;
