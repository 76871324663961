import React from 'react';       
import {
  Stack
  } from '@mui/material';
import SimpleLink from '../../../common/SimpleLink';

const QuestionsContainer = ({props}) => {
  const {
    renderQuestion,
    setEdit,
    taskIndex,
    question,
    questionIndex,
    setShowEditInput,
    setShowAnswerInput,
    setCurrentTask,
    setCurrentQuestion
  } = props;

  return (<>
    <div style={{
      display: 'flex',
      margin: '3px 0 0 15px'
    }}>
      {renderQuestion(question)}
      &nbsp;
      <Stack style={{
        display: 'inline'
      }} spacing={0.5} direction="row">
        <SimpleLink
          events={() => {
            setEdit({
              type: 'question',
              taskId: taskIndex,
              questionId: questionIndex,
              answerId: undefined,
              newValue: question.name
            });
            setShowEditInput(true);
          }}
          style={{
            fontSize: 15
          }}
        >
          Edit
        </SimpleLink>
        <span style={{
          fontSize: 15
        }}>or</span>
        <SimpleLink
          events={() => {
            setShowAnswerInput(questionIndex);
            setCurrentTask(taskIndex);
            setCurrentQuestion(questionIndex);
          }}
          style={{
            fontSize: 15
          }}
        >
          Reply +
        </SimpleLink>
      </Stack>
    </div>
  </>);
}

export default QuestionsContainer;